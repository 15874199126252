
import { computed, defineAsyncComponent, defineComponent, onMounted, reactive, ref, unref, watch } from 'vue'
import TheEmployeesDatatable from '@/views/mc-admin-employees/TheEmployeesDatatable.vue'
import ActivityCard2 from '@/views/mc-admin-library-manager/ActivityCard2.vue'
import { getApiKey } from '@/core/mc-admin-helpers/tinymce'
// import Editor from '@tinymce/tinymce-vue'
import { QuillEditor as quill } from '@vueup/vue-quill'

import {
  buildTemplateImageUrl,
  buildTemplateImageUri,
  formatActivitiesTableDate,
  formatHumanDate,
  formatLastActionDatatable,
  formatSecondsToMinutes,
  getEtagForTemplate
} from '@/core/mc-admin-helpers/activity-template'
import { useStore } from 'vuex'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import { MenuComponent } from '@/assets/ts/components'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { ElNotification } from 'element-plus/es'
import TheActivitiesDatatable from '@/views/mc-admin-library-manager/TheActivitiesDatatable.vue'
import moment from 'moment'
import { useRouter } from 'vue-router'
import { LearningPath, LearningPathSettings, UpdateLearningPath } from '@/store/modules/LearningPathModule'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import LearningPathGroup from '@/views/mc-admin-learning-paths/LearningPathGroup.vue'

// Lazy load the component we want to pass
const TimePicker = defineAsyncComponent(() => import('@/views/mc-admin-learning-paths/TimePickerCustom.vue'))

export default defineComponent({
  name: 'deploy-learning-path',
  components: {
    LearningPathGroup,
    TheActivitiesDatatable,
    TheEmployeesDatatable,
    Datepicker,
    // editor: Editor,
    ActivityCard2,
    quill

  },
  setup () {
    const objective = ref('')
    const deployLoading = ref(false)
    const deployDialogVisible = ref(false)
    const participantsDialogVisible = ref(false)
    const activitiesDialogVisible = ref(false)
    const createLearningGroupDialog = ref(false)
    const createGroupTitle = ref()
    const selectedFacilitator = ref()
    const facilitatorsLoading = ref(false)
    const facilitators = ref([])
    const facilitatedScheduledAt = ref()
    const store = useStore()
    const router = useRouter()
    // const addedParticipants = ref([]) as any
    const allTeams = ref([]) as any
    const allGroups = computed(() => store.getters.learningPathGroups) as any
    const socket = computed(() => { return store.getters.getSocket })
    const quillOptions = ref({
      modules: {
        toolbar: false
      }
    })
    const quillEditorTitle = ref()
    const quillEditorDescription = ref()
    const errors = reactive({ error: computed(() => store.getters.getAdminErrors) })
    watch(() => errors.error, (newError, oldErrors) => {
      if (newError.code.includes('learningPath')) {
        deployLoading.value = false
      }
    })

    const learningPathUuid = router.currentRoute.value.params.id
    const learningPath = computed(() => store.getters.getLearningPath) as unknown as LearningPath
    const addedParticipants = ref([]) as any
    const addedActivities = ref([]) as any
    // Return from computed as it is imported
    const timePicker = computed(() => TimePicker)
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const checkedEmployees = ref([]) as any
    const allAddedParticipants = ref([]) as any

    const handleOnClickedItem = (item) => {
      if (checkedEmployees.value.find(employee => employee.userUuid === item.userUuid)) {
        checkedEmployees.value = checkedEmployees.value.filter(employee => employee.userUuid !== item.userUuid)
      } else {
        checkedEmployees.value.push(item)
      }
    }

    const handleScheduledAtUpdated = (val) => {
      if (!moment.isDate(facilitatedScheduledAt.value)) {
        return
      }
      let scheduledAtChangedTimeout = 0
      const date = moment(String(new Date(facilitatedScheduledAt.value).toISOString())).format('yyyy-MM-DD HH:mm')
      console.log('facilitatedScheduledAt', date)

      clearTimeout(scheduledAtChangedTimeout)
      scheduledAtChangedTimeout = setTimeout(() => {
        const payload = {} as any
        const updateLearningPath = {} as UpdateLearningPath
        updateLearningPath.scheduledAt = date
        payload.uuid = learningPathUuid
        payload.data = updateLearningPath
        store.dispatch(Actions.API_UPDATE_LEARNING_PATH, payload).then((response) => {
          // ElNotification.success({
          //   message: 'Learning path updated',
          //   dangerouslyUseHTMLString: true,
          //   customClass: 'houston-notification',
          //   position: 'top-left'
          // })
        }).catch((response) => {
          ElNotification.error({
            message: response.data.error.message,
            dangerouslyUseHTMLString: true,
            customClass: 'houston-notification',
            position: 'top-left'
          })
        })
      }, 1000)
    }
    const handleChooseFacilitator = () => {
      console.log('handleChooseFacilitator', selectedFacilitator.value)
      const payload = {} as any
      const updateLearningPath = {} as UpdateLearningPath
      updateLearningPath.facilitators = selectedFacilitator.value
      payload.uuid = learningPathUuid
      payload.data = updateLearningPath
      store.dispatch(Actions.API_UPDATE_LEARNING_PATH, payload).then((response) => {
        // ElNotification.success({
        //   message: 'Learning path updated',
        //   dangerouslyUseHTMLString: true,
        //   customClass: 'houston-notification',
        //   position: 'top-left'
        // })
      }).catch((response) => {
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left'
        })
      })
    }
    const remoteFacilitatorSearchMethod = () => {
      const limit = 1000
      const offset = 0
      const filter = { limit, offset } as any
      filter.in_role = 2 // only trainers
      // if (searchFilter.value) {
      //     filter.keyword = searchFilter.value
      // }
      store.dispatch(Actions.API_GET_AVAILABLE_EMPLOYEES, { learningPathUuid: learningPathUuid, filter: filter })
        .then(response => {
          const data = response.payload.availableEmployees
          console.log('found these employees: ', data)
          facilitators.value = facilitators.value.concat(data)
          const participantIndexMap = new Map()
          // Initialize the map with the original indices
          facilitators.value.forEach((participant, index) => {
            participantIndexMap.set(participant, index)
          })
        })
        .catch(error => {
          console.error('API error:', error)
        })
    }

    const selectedActivityInLearningPath = ref({}) as any

    const lowerLimitDate = ref(new Date())
    const upperLimitDate = ref('')
    const lowerLimitTime = ref(null) as any
    const upperLimitTime = ref(null) as any

    const handleDateSelection = (date) => {
      lowerLimitTime.value = calculateMinTime(selectedActivityInLearningPath.value, date)
      upperLimitTime.value = calculateMaxTime(selectedActivityInLearningPath.value, date)
    }

    const calculateMinTime = (selectedActivity, selectedDate) => {
      let previousKeys = 0 as any
      let previousDates = [] as any
      let minDate
      let lastAddedActivityKey = 0
      addedActivities.value.forEach((addedActivity, key, a) => {
        if (selectedActivity.activityTemplateUuid === addedActivity.activityTemplateUuid) {
          if (key === 0) {
            minDate = new Date()
            return false
          }
          if (key > 0) {
            previousDates = []
            previousKeys = key
            for (let i = 0; i < previousKeys; i++) {
              if (dateByActivity.value[addedActivities.value[i].activityTemplateUuid] &&
                  moment(String(new Date(dateByActivity.value[addedActivities.value[i].activityTemplateUuid]).toISOString())).format('MM-DD-YYYY') === moment(String(new Date(selectedDate).toISOString())).format('MM-DD-YYYY')) {
                previousDates.push(dateByActivity.value[addedActivities.value[i].activityTemplateUuid])
              }
            }
            if (!previousDates.length) {
              minDate = new Date()
              return false
            }
            const moments = previousDates.map((d) => moment(d))

            const maxDate = moment.max(moments)
            moments.forEach((momentObject, k) => {
              if (momentObject === maxDate) {
                lastAddedActivityKey = k
              }
            })
            minDate = maxDate.toDate()
          }
        }
      })

      const minTime = {} as any

      if (previousDates.length) {
        minTime.hours = moment(String(new Date(minDate).toISOString())).add(addedActivities.value[lastAddedActivityKey].duration, 'seconds').format('HH')
        minTime.minutes = moment(String(new Date(minDate).toISOString())).add(addedActivities.value[lastAddedActivityKey].duration, 'seconds').format('mm')
      }
      return minTime
    }

    const calculateMaxTime = (selectedActivity, selectedDate) => {
      let nextKeys = 0 as any
      let nextDates = [] as any
      let minDate
      let lastAddedActivityKey = 0
      addedActivities.value.forEach((addedActivity, key, a) => {
        if (selectedActivity.activityTemplateUuid === addedActivity.activityTemplateUuid) {
          if (key >= 0) {
            nextDates = []
            nextKeys = key + 1
            for (let i = nextKeys; i < addedActivities.value.length - nextKeys; i++) {
              if (dateByActivity.value[addedActivities.value[i].activityTemplateUuid] &&
                            moment(String(new Date(dateByActivity.value[addedActivities.value[i].activityTemplateUuid]).toISOString())).format('MM-DD-YYYY') === moment(String(new Date(selectedDate).toISOString())).format('MM-DD-YYYY')) {
                nextDates.push(dateByActivity.value[addedActivities.value[i].activityTemplateUuid])
              }
            }
            if (!nextDates.length) {
              minDate = new Date()
              return false
            }
            const moments = nextDates.map((d) => moment(d))

            const maxDate = moment.min(moments)
            moments.forEach((momentObject, k) => {
              if (momentObject === maxDate) {
                lastAddedActivityKey = k
              }
            })
            minDate = maxDate.toDate()
          }
        }
      })

      const maximumTime = {} as any

      if (nextDates.length) {
        maximumTime.hours = moment(String(new Date(minDate).toISOString())).subtract(addedActivities.value[lastAddedActivityKey].duration, 'seconds').format('HH')
        maximumTime.minutes = moment(String(new Date(minDate).toISOString())).subtract(addedActivities.value[lastAddedActivityKey].duration, 'seconds').format('mm')
      } else {
        return null
      }
      return maximumTime
    }

    const calculateMinDate = (selectedActivity) => {
      let previousKeys = 0 as any
      let previousDates = [] as any
      let minDate
      addedActivities.value.forEach((addedActivity, key, a) => {
        if (selectedActivity.activityTemplateUuid === addedActivity.activityTemplateUuid) {
          if (key === 0) {
            minDate = new Date()
            return false
          }
          if (key > 0) {
            previousDates = []
            previousKeys = key
            for (let i = 0; i < previousKeys; i++) {
              if (dateByActivity.value[addedActivities.value[i].activityTemplateUuid]) {
                previousDates.push(dateByActivity.value[addedActivities.value[i].activityTemplateUuid])
              }
            }
            if (!previousDates.length) {
              minDate = new Date()
              return false
            }
            const moments = previousDates.map(d => moment(d))
            const maxDate = moment.max(moments)
            minDate = maxDate.toDate()
          }
        }
      })
      return minDate
    }

    const calculateMaxDate = (selectedActivity) => {
      let nextKeys = 0 as any
      let nextDates = [] as any
      let maxDate
      addedActivities.value.forEach((addedActivity, key, a) => {
        if (selectedActivity.activityTemplateUuid === addedActivity.activityTemplateUuid) {
          if (key === 0) {
            nextDates = []
            nextKeys = key
            for (let i = addedActivities.value.length - 1; i > nextKeys; i--) {
              if (dateByActivity.value[addedActivities.value[i].activityTemplateUuid]) {
                nextDates.push(dateByActivity.value[addedActivities.value[i].activityTemplateUuid])
              }
            }
            if (!nextDates.length) {
              maxDate = ''
              return
            }
            const moments = nextDates.map(d => moment(d))
            const minDate = moment.min(moments)
            maxDate = minDate.toDate()
          }
          if (key > 0) {
            nextDates = []
            nextKeys = key + 1
            for (let i = nextKeys; i <= addedActivities.value.length - 1; i++) {
              if (dateByActivity.value[addedActivities.value[i].activityTemplateUuid]) {
                nextDates.push(dateByActivity.value[addedActivities.value[i].activityTemplateUuid])
              }
            }
            if (!nextDates.length) {
              maxDate = ''
              return
            }
            const moments = nextDates.map(d => moment(d))
            const minDate = moment.min(moments)
            maxDate = minDate.toDate()
          }
        }
      })
      return maxDate
    }

    const onDatePickerOpen = (selectedActivity) => {
      if (dateByActivity.value[selectedActivity.activityTemplateUuid]) {
        lowerLimitTime.value = calculateMinTime(selectedActivity, dateByActivity.value[selectedActivity.activityTemplateUuid])
        upperLimitTime.value = calculateMaxTime(selectedActivity, dateByActivity.value[selectedActivity.activityTemplateUuid])
      }

      selectedActivityInLearningPath.value = selectedActivity

      lowerLimitDate.value = calculateMinDate(selectedActivity)
      upperLimitDate.value = calculateMaxDate(selectedActivity)
    }

    const onAssignUser = (employee, userRole) => {
      const data = {} as any
      const payload = {} as any
      const updatedUser = JSON.parse(JSON.stringify(employee))
      updatedUser.userRole = userRole
      data.user = updatedUser
      payload.learningPathUuid = learningPathUuid
      payload.data = data
      store.dispatch(Actions.API_UPDATE_LEARNING_PATH_USER, payload).then((response) => {
        addedParticipants.value.forEach((participant) => {
          if (response.payload.user.userRole === 2 && participant.userRole === 2) {
            participant.userRole = 1
          }
          if (participant.userUuid === response.payload.user.userUuid) {
            participant.userRole = response.payload.user.userRole
          }
        })
        allTeams.value.forEach((teamParticipants) => {
          teamParticipants.forEach((participant) => {
            if (response.payload.user.userRole === 2 && participant.userRole === 2) {
              participant.userRole = 1
            }
            if (participant.userUuid === response.payload.user.userUuid) {
              participant.userRole = response.payload.user.userRole
            }
          })
        })
        store.commit(Mutations.SET_LEARNING_PATH_EMPLOYEES, addedParticipants.value)
        store.commit(Mutations.SET_LEARNING_PATH_TEAMS, allTeams.value)
        // ElNotification.success({
        //   message: 'Employee "' + employee.fullName + '" was successfully updated',
        //   dangerouslyUseHTMLString: true,
        //   customClass: 'houston-notification',
        //   position: 'top-left'
        // })
      }).catch((response) => {
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left'
        })
      })
    }
    function isValidString (s) {
      // Use a regular expression to check if the string contains only letters, numbers, and spaces
      if (/^[a-zA-Z0-9 ]+$/.test(s)) {
        // Check if the string contains at least three letters or numbers
        if ((s.match(/[a-zA-Z0-9]/g) || []).length >= 3) {
          return true
        }
      }
      return false
    }
    const addGroupError = ref() as any
    const onAddGroupActionClicked = () => {
      if (!isValidString(createGroupTitle.value)) {
        addGroupError.value = 'The group needs to have at least 3 letters or numbers'
        return
      }
      addGroupError.value = null
      const addGroupPayload = {} as any
      addGroupPayload.groupTitle = createGroupTitle.value.trim()
      addGroupPayload.learningPathUuid = learningPathUuid
      store.dispatch(Actions.API_ADD_GROUP_TO_LEARNING_PATH, addGroupPayload).then((response) => {
        createLearningGroupDialog.value = false
        store.commit(Mutations.UPDATE_LEARNING_PATH_ADD_GROUP, response.payload.addedGroup)
      }).catch((response) => {
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left'
        })
      })
    }

    const learningPathSettings = ref(computed(() => store.getters.getLearningPathSettings)) as unknown as LearningPathSettings

    const limit = ref(25)
    let employeesPayload = { filter: { limit: limit.value, offset: 0 } }

    const tableData = ref(computed(() => store.getters.getEmployees))
    const tableDataActivities = ref(computed(() => store.getters.getActivityTemplates))

    const loadMore = () => {
    }
    const tableSearch = ref('')
    const searchParticipantString = ref('')
    const itemsPerPage = ref(25)
    const selectedPage = ref(1)

    const dateByActivity = ref([]) as any

    const tableSearchActivities = ref('')
    const itemsPerPageActivities = ref(25)
    const selectedPageActivities = ref(1)

    const employeesCount = computed(() => store.getters.getEmployeesCount)
    const tablePagesCount = computed(() => Math.ceil(employeesCount.value / itemsPerPage.value))
    const tableLoading = computed(() => store.getters.getEmployeesLoading)
    const tableLoadingActivities = computed(() => store.getters.getActivityTemplatesRequestPending)
    const templatesCount = computed(() => store.getters.getActivityTemplatesCount)
    const tablePagesCountActivities = computed(() => Math.ceil(templatesCount.value / itemsPerPageActivities.value))

    const selectedEmployees = ref([]) as any
    const checkedParticipants = ref([]) as any

    const addedParticipantsModel = ref([]) as any
    const checkedActivities = ref([]) as any

    const learningPathTitle = ref('<h3><strong>Untitled new learning path</strong></h3>')
    const learningPathDescription = ref('<span>No description</span>')

    const generatedRandomColor = (characters = '') => {
      // TODO: adjust base colour values below based on theme
      const baseRed = 300
      const baseGreen = 300
      const baseBlue = 300

      // lazy seeded random hack to get values from 0 - 256
      // for seed just take bitwise XOR of first two chars
      let seed = characters.charCodeAt(0) ^ characters.charCodeAt(1)
      const rand1 = Math.abs((Math.sin(seed++) * 10000)) % 256
      const rand2 = Math.abs((Math.sin(seed++) * 10000)) % 256
      const rand3 = Math.abs((Math.sin(seed++) * 10000)) % 256

      // build colour
      const red = Math.round((rand1 + baseRed) / 2)
      const green = Math.round((rand2 + baseGreen) / 2)
      const blue = Math.round((rand3 + baseBlue) / 2)
      return 'rgb(' + red + ',' + green + ',' + blue + ')'
    }

    const onLoadMoreParticipants = () => {}
    const onLoadMoreActivities = () => {}
    const onCheckParticipant = (elem) => {
    }

    let titleChangedTimeout = 0 as any
    const onLearningPathTitleChanged = (editor) => {
      const newTitle = editor.editor.__quill.getText()

      clearTimeout(titleChangedTimeout)
      titleChangedTimeout = setTimeout(() => {
        const payload = {} as any
        const updateLearningPath = {} as UpdateLearningPath
        updateLearningPath.title = newTitle
        payload.uuid = learningPathUuid
        payload.data = updateLearningPath
        store.dispatch(Actions.API_UPDATE_LEARNING_PATH, payload).then((response) => {
          // ElNotification.success({
          //   message: 'Learning path updated',
          //   dangerouslyUseHTMLString: true,
          //   customClass: 'houston-notification',
          //   position: 'top-left'
          // })
        }).catch((response) => {
          ElNotification.error({
            message: response.data.error.message,
            dangerouslyUseHTMLString: true,
            customClass: 'houston-notification',
            position: 'top-left'
          })
        })
      }, 1000)
    }

    const onLearningPathDescriptionChanged = (editor) => {
      const newDescription = editor.editor.__quill.getText()

      clearTimeout(titleChangedTimeout)
      titleChangedTimeout = setTimeout(() => {
        const payload = {} as any
        const updateLearningPath = {} as UpdateLearningPath
        updateLearningPath.description = newDescription
        payload.uuid = learningPathUuid
        payload.data = updateLearningPath
        store.dispatch(Actions.API_UPDATE_LEARNING_PATH, payload).then((response) => {
          // ElNotification.success({
          //   message: 'Learning path updated',
          //   dangerouslyUseHTMLString: true,
          //   customClass: 'houston-notification',
          //   position: 'top-left'
          // })
        }).catch((response) => {
          ElNotification.error({
            message: response.data.error.message,
            dangerouslyUseHTMLString: true,
            customClass: 'houston-notification',
            position: 'top-left'
          })
        })
      }, 1000)
    }

    const onLearningPathObjectiveChanged = (event) => {
      clearTimeout(titleChangedTimeout)
      titleChangedTimeout = setTimeout(() => {
        const payload = {} as any
        const updateLearningPath = {} as UpdateLearningPath
        updateLearningPath.objective = unref(learningPath).objective
        payload.uuid = learningPathUuid
        payload.data = updateLearningPath
        store.dispatch(Actions.API_UPDATE_LEARNING_PATH, payload).then((response) => {
          // ElNotification.success({
          //   message: 'Learning path updated',
          //   dangerouslyUseHTMLString: true,
          //   customClass: 'houston-notification',
          //   position: 'top-left'
          // })
        }).catch((response) => {
          ElNotification.error({
            message: response.data.error.message,
            dangerouslyUseHTMLString: true,
            customClass: 'houston-notification',
            position: 'top-left'
          })
        })
      }, 1000)
    }

    const editorSettings = {
      path: '/tinymce/js/tinymce',
      setup: (editor) => {
      },
      plugins: [],
      inline: true,
      menubar: false,
      toolbar: false
    }
    const allParticipantsMustAnswer = ref(false)
    const notifyManager = ref(true)
    const onCheckNotifyManager = () => {
      const payload = {} as any
      const updateLearningPath = {} as UpdateLearningPath
      updateLearningPath.settings = {} as LearningPathSettings
      updateLearningPath.settings.allowNotifyManagers = unref(learningPathSettings).allowNotifyManagers
      payload.uuid = learningPathUuid
      payload.data = updateLearningPath
      store.dispatch(Actions.API_UPDATE_LEARNING_PATH, payload).then((response) => {
        // ElNotification.success({
        //   message: 'Learning path updated',
        //   dangerouslyUseHTMLString: true,
        //   customClass: 'houston-notification',
        //   position: 'top-left'
        // })
      }).catch((response) => {
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left'
        })
      })
    }
    const onCheckAllParticipantsMustAnswer = (value) => {
      const payload = {} as any
      const updateLearningPath = {} as UpdateLearningPath
      updateLearningPath.settings = {} as LearningPathSettings
      updateLearningPath.settings.allowMultipleAnswers = unref(learningPathSettings).allowMultipleAnswers
      payload.uuid = learningPathUuid
      payload.data = updateLearningPath
      store.dispatch(Actions.API_UPDATE_LEARNING_PATH, payload).then((response) => {
        // ElNotification.success({
        //   message: 'Learning path updated',
        //   dangerouslyUseHTMLString: true,
        //   customClass: 'houston-notification',
        //   position: 'top-left'
        // })
      }).catch((response) => {
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left'
        })
      })
    }
    const onCheckAllParticipantsHavePreIntro = (value) => {
      const payload = {} as any
      const updateLearningPath = {} as UpdateLearningPath
      updateLearningPath.settings = {} as LearningPathSettings
      updateLearningPath.settings.allowPreIntroScreen = unref(learningPathSettings).allowPreIntroScreen
      payload.uuid = learningPathUuid
      payload.data = updateLearningPath
      store.dispatch(Actions.API_UPDATE_LEARNING_PATH, payload).then((response) => {
      }).catch((response) => {
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left'
        })
      })
    }
    const onAllParticipantsAreInClassRoom = (value) => {
      const payload = {} as any
      const updateLearningPath = {} as UpdateLearningPath
      updateLearningPath.settings = {} as LearningPathSettings
      updateLearningPath.settings.isInClassroom = unref(learningPathSettings).isInClassroom
      payload.uuid = learningPathUuid
      payload.data = updateLearningPath
      store.dispatch(Actions.API_UPDATE_LEARNING_PATH, payload).then((response) => {
      }).catch((response) => {
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left'
        })
      })
    }
    const onIsFacilitatedChecked = (value) => {
      const payload = {} as any
      const updateLearningPath = {} as UpdateLearningPath
      updateLearningPath.settings = {} as LearningPathSettings
      updateLearningPath.settings.isFacilitated = unref(learningPathSettings).isFacilitated
      payload.uuid = learningPathUuid
      payload.data = updateLearningPath
      store.dispatch(Actions.API_UPDATE_LEARNING_PATH, payload).then((response) => {
      }).catch((response) => {
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left'
        })
      })
    }
    const selectAllPerPageCheckbox = ref({})
    const onCheckEmployee = (elem) => {

    }

    const onRemoveParticipant = (participant) => {
      const removePayload = { } as any
      removePayload.users = []
      removePayload.users.push(participant)
      removePayload.learningPathUuid = learningPathUuid

      store.dispatch(Actions.API_REMOVE_USERS_FROM_LEARNING_PATH, removePayload).then((response) => {
        addedParticipantsModel.value = []
        allTeams.value = response.payload.teams
        checkedEmployees.value = response.payload.employees
        addedParticipants.value = response.payload.employees
        addedParticipants.value = orderByGroupNumber(response.payload.employees)
        store.commit(Mutations.SET_LEARNING_PATH_EMPLOYEES, response.payload.employees)
        store.commit(Mutations.SET_LEARNING_PATH_TEAMS, response.payload.teams)
        allAddedParticipants.value = []
        addedParticipants.value.forEach((participant) => {
          allAddedParticipants.value.push(participant)
        })
        setTimeout(() => {
          MenuComponent.reinitialization()
        }, 200)
      }).catch((response) => {
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left',
          duration: 9000
        })
      })
    }

    const onRemoveParticipants = () => {
      const removePayload = { } as any
      removePayload.users = addedParticipantsModel.value
      removePayload.learningPathUuid = learningPathUuid

      Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light '
        },
        buttonsStyling: false
      }).fire({
        title: 'Are you sure you want to remove ' + addedParticipantsModel.value.length + ' participant' + (addedParticipantsModel.value.length > 1 ? 's' : '') + '?',
        text: 'This action will re-create the groups',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove',
        cancelButtonText: 'No, cancel',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          return store.dispatch(Actions.API_REMOVE_USERS_FROM_LEARNING_PATH, removePayload).then((response) => {
            return response
          }).catch((response) => {
            ElNotification.error({
              message: response.data.error.message,
              dangerouslyUseHTMLString: true,
              customClass: 'houston-notification',
              position: 'top-left',
              duration: 9000
            })
          })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          addedParticipantsModel.value = []
          allTeams.value = result.value.payload.teams
          checkedEmployees.value = result.value.payload.employees
          addedParticipants.value = result.value.payload.employees
          addedParticipants.value = orderByGroupNumber(result.value.payload.employees)
          store.commit(Mutations.SET_LEARNING_PATH_EMPLOYEES, result.value.payload.employees)
          store.commit(Mutations.SET_LEARNING_PATH_TEAMS, result.value.payload.teams)
          allAddedParticipants.value = []
          addedParticipants.value.forEach((participant) => {
            allAddedParticipants.value.push(participant)
          })
          setTimeout(() => {
            MenuComponent.reinitialization()
          }, 200)
        }
      })
    }

    const removeActivityLoading = ref([]) as any

    const onRemoveActivity = (activity) => {
      removeActivityLoading.value.push(activity.activityTemplateUuid)
      const removePayload = { } as any
      removePayload.activities = []
      removePayload.activities.push(activity)
      removePayload.learningPathUuid = learningPathUuid

      store.dispatch(Actions.API_REMOVE_ACTIVITY_FROM_LEARNING_PATH, removePayload).then((response) => {
        checkedActivities.value = response.payload.activities
        addedActivities.value = response.payload.activities
        store.commit(Mutations.SET_LEARNING_PATH_ACTIVITIES, response.payload.activities)
        removeActivityLoading.value = removeActivityLoading.value.filter(a => a.activityTemplateUuid !== activity.activityTemplateUuid)

        dateByActivity.value = Object.fromEntries(Object.entries(dateByActivity.value).filter(([key]) => !key.includes(activity.activityTemplateUuid)))
        setTimeout(() => {
          MenuComponent.reinitialization()
        }, 200)
      }).catch((response) => {
        removeActivityLoading.value = removeActivityLoading.value.filter(a => a !== activity.activityTemplateUuid)
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left',
          duration: 9000
        })
      })
    }

    const onCheckActivity = (elem) => {}

    const handleActivitiesDate = (activity) => {
      const activityUuid = activity.activityTemplateUuid
      const activityDateValue = dateByActivity.value[activityUuid]
      const startMonthDayYear = moment(String(new Date(activityDateValue).toISOString())).format('MMMM D, YYYY')
      const startTimeHourMinute = moment(String(new Date(activityDateValue).toISOString())).format('HH:mm')
      const finishTimeHourMinute = moment(String(new Date(activityDateValue).toISOString())).add(activity.duration, 'seconds').format('HH:mm')

      addedActivities.value.forEach((value, index, array) => {
        if (value.activityTemplateUuid === activityUuid) {
          addedActivities.value[index].date = startMonthDayYear
          addedActivities.value[index].time = {}
          addedActivities.value[index].time.start = startTimeHourMinute
          addedActivities.value[index].time.end = finishTimeHourMinute
        }
      })

      addDateTimeToActivity(activity)

      const data = {} as any
      const payload = {} as any
      data.activity = JSON.parse(JSON.stringify(activity))
      payload.learningPathUuid = learningPathUuid
      payload.data = data
      store.dispatch(Actions.API_UPDATE_LEARNING_PATH_ACTIVITY, payload).then((response) => {
        // addedParticipants.value.forEach((participant) => {
        //   if (participant.userUuid === response.payload.user.userUuid) {
        //     participant.userRole = response.payload.user.userRole
        //   }
        // })
        // allTeams.value.forEach((teamParticipants) => {
        //   teamParticipants.forEach((participant) => {
        //     if (participant.userUuid === response.payload.user.userUuid) {
        //       participant.userRole = response.payload.user.userRole
        //     }
        //   })
        // })
        // store.commit(Mutations.SET_LEARNING_PATH_EMPLOYEES, addedParticipants.value)
        // store.commit(Mutations.SET_LEARNING_PATH_TEAMS, allTeams.value)
        // ElNotification.success({
        //   message: 'Activity "' + activity.title + '" was successfully updated',
        //   dangerouslyUseHTMLString: true,
        //   customClass: 'houston-notification',
        //   position: 'top-left'
        // })
      }).catch((response) => {
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left'
        })
      })
    }

    const formatDate = (date) => {
      return moment(String(new Date(date).toISOString())).format('MMMM D, YYYY')
    }

    const addDateTimeToActivity = (activity) => {
      const activityUuid = activity.activityTemplateUuid
      const activityDateValue = dateByActivity.value[activityUuid]
      if (moment.isDate(activityDateValue)) {
        const startMonthDayYear = moment(String(new Date(activityDateValue).toISOString())).format('yyyy-MM-DD')
        const startTimeHourMinute = moment(String(new Date(activityDateValue).toISOString())).format('HH:mm')
        const finishTimeHourMinute = moment(String(new Date(activityDateValue).toISOString())).add(activity.duration, 'seconds').format('HH:mm')
        activity.date = startMonthDayYear
        activity.time = {}
        activity.time.timezone = userTimezone
        activity.time.start = startTimeHourMinute
        activity.time.end = finishTimeHourMinute
        return
      }
      if (!activityDateValue) {
        return
      }
      activity.date = activityDateValue.date
      activity.time = {}
      activity.time.start = activityDateValue.time.start
      activity.time.end = activityDateValue.time.end
    }

    const teamMinSize = ref(2)
    // const teamMaxSize = ref(7)

    const getGroupNumber = (employeeUuid) => {
      let groupTeamNumber = 0
      allTeams.value.forEach((group, groupNumber, array) => {
        group.forEach((participant, index, participantsArray) => {
          if (participant.userUuid === employeeUuid) {
            groupTeamNumber = groupNumber + 1
          }
        })
      })
      return groupTeamNumber
    }

    const onAddParticipants = () => {
      allTeams.value = []

      if (checkedEmployees.value.length < teamMinSize.value) {
        ElNotification.warning({
          message: 'At least ' + teamMinSize.value + ' participants must be selected',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }

      const payload = {} as any

      payload.users = checkedEmployees.value
      payload.learningPathUuid = learningPathUuid

      store.dispatch(Actions.API_ADD_USERS_TO_LEARNING_PATH, payload).then((response) => {
        addedParticipantsModel.value = []
        allTeams.value = response.payload.teams
        selectedEmployees.value = response.payload.employees
        addedParticipants.value = response.payload.employees
        addedParticipants.value = orderByGroupNumber(response.payload.employees)
        store.commit(Mutations.SET_LEARNING_PATH_EMPLOYEES, response.payload.employees)
        store.commit(Mutations.SET_LEARNING_PATH_TEAMS, response.payload.teams)
        setTimeout(() => {
          MenuComponent.reinitialization()
        }, 200)
        participantsDialogVisible.value = false
      }).catch((response) => {
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left'
        })
      })
    }

    const onAddActivities = () => {
      if (checkedActivities.value.length < 1) {
        ElNotification.warning({
          message: 'At least one activity must be selected',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification'
        })
        return false
      }
      const payload = {} as any

      const checkedActivitiesPayload = ref(JSON.parse(JSON.stringify(checkedActivities.value)))

      checkedActivitiesPayload.value.forEach((checked) => {
        addDateTimeToActivity(checked)
      })

      payload.activities = checkedActivitiesPayload.value
      payload.learningPathUuid = learningPathUuid

      store.dispatch(Actions.API_ADD_ACTIVITY_TO_LEARNING_PATH, payload).then((response) => {
        addedActivities.value = response.payload.activities
        checkedActivities.value = response.payload.activities
        store.commit(Mutations.SET_LEARNING_PATH_ACTIVITIES, response.payload.activities)

        removeActivityLoading.value = []
        setTimeout(() => {
          MenuComponent.reinitialization()
        }, 200)
        activitiesDialogVisible.value = false
      }).catch((response) => {
        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left'
        })
      })
    }
    let timeoutId = 0

    const selectAllParticipants = ref(false)
    const onSelectAllParticipants = () => {

    }

    const onItemsSearch = () => {
      clearTimeout(timeoutId)
      let payload = {}
      payload = {
        filter: {
          keyword: tableSearch.value,
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (selectedPage.value - 1)
        }
      }
      const countPayload = { filter: { keyword: tableSearch.value } }
      timeoutId = setTimeout(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, payload).then(() => {
          store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES_COUNT, countPayload).then(() => {
            checkedEmployees.value = addedParticipants.value
            addedParticipants.value.forEach((employee) => {
              tableData.value.forEach((participant) => {
                if (participant.userUuid === employee.userUuid && participant.userRole !== employee.userRole) {
                  participant.userRole = employee.userRole
                }
              })
              checkedEmployees.value.forEach((participant) => {
                if (participant.userUuid === employee.userUuid && participant.userRole !== employee.userRole) {
                  participant.userRole = employee.userRole
                }
              })
            })
          })
        })
      }, 500)

      watch(tableData, (n, o) => {
        addedParticipants.value.forEach((employee) => {
          n.forEach((participant) => {
            if (participant.userUuid === employee.userUuid && participant.userRole !== employee.userRole) {
              participant.userRole = employee.userRole
            }
          })
        })
      })
    }

    const onItemsSearchActivities = () => {
      clearTimeout(timeoutId)
      let payload = {} as any
      payload = {
        filter: {
          title: tableSearchActivities.value,
          limit: itemsPerPageActivities.value,
          offset: itemsPerPageActivities.value * (selectedPageActivities.value - 1),
          status: 2
        }
      }
      payload.filter.orderBy = {}
      payload.filter.orderBy[activeSortBy.value] = 'desc'
      const countPayload = { filter: { title: tableSearchActivities.value, status: 2 } }
      timeoutId = setTimeout(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload).then(() => {
          store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT, countPayload).then(() => {
            MenuComponent.reinitialization()
          })
        })
      }, 500)
    }

    const infiniteHandler = ($state) => {
      // todo make infinite handler work -- important for performance
      limit.value = limit.value + 5
      employeesPayload = { filter: { limit: limit.value, offset: 0 } }
      store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, employeesPayload).then(() => {
        $state.loaded()
      })
    }

    const handleSelectionChange = (val) => {}

    const onCurrentPageChange = (page) => {
      selectedPage.value = page
      let payload = {} as any
      payload = {
        filter: {
          limit: itemsPerPage.value,
          offset: itemsPerPage.value * (page - 1)
        }
      }
      if (tableSearch.value !== '') {
        payload.filter.keyword = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, payload).then(() => {})
    }
    const onItemsPerPageChange = (rowsPerPage, page) => {
      itemsPerPage.value = rowsPerPage
      let payload = {} as any
      payload = {
        filter: {
          limit: rowsPerPage,
          offset: rowsPerPage * (selectedPage.value - 1)
        }
      }
      if (tableSearch.value !== '') {
        payload.filter.keyword = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, payload).then(() => {})
    }

    const activeSortBy = ref('lastOpened')

    const onParticipantsDialogCLose = () => {
      addedParticipants.value = typeof (unref(learningPath).deployMetadata.employees) !== 'undefined' ? unref(learningPath).deployMetadata.employees : []
      checkedEmployees.value = typeof (unref(learningPath).deployMetadata.employees) !== 'undefined' ? unref(learningPath).deployMetadata.employees!.filter(item => true) : []
      allTeams.value = typeof (unref(learningPath).deployMetadata.teams) !== 'undefined' ? unref(learningPath).deployMetadata.teams : []
      addedParticipants.value = orderByGroupNumber(addedParticipants.value)
      allAddedParticipants.value = []
      addedParticipants.value.forEach((participant) => {
        allAddedParticipants.value.push(participant)
      })
    }

    const onActivitiesDialogClose = () => {
      // addedActivities.value = typeof (unref(learningPath).deployMetadata.activities) !== 'undefined' ? unref(learningPath).deployMetadata.activities : []
      addedActivities.value.forEach((addedActivity) => {
        addDateTimeToActivity(addedActivity)
      })
      checkedActivities.value = typeof (unref(learningPath).deployMetadata.activities) !== 'undefined' ? unref(learningPath).deployMetadata.activities!.filter(item => true) : []
    }

    const onActivitiesDialogOpen = () => {
      checkedActivities.value = typeof (unref(learningPath).deployMetadata.activities) !== 'undefined' ? JSON.parse(JSON.stringify(unref(learningPath))).deployMetadata.activities : []
      checkedActivities.value.forEach((checkedActivity) => {
        delete checkedActivity.time
        delete checkedActivity.date
      })

      addedActivities.value = typeof (unref(learningPath).deployMetadata.activities) !== 'undefined' ? unref(learningPath).deployMetadata.activities!.filter(item => true) : []
    }

    const onParticipantsDialogOpen = () => {
      unref(addedParticipants).forEach((addedParticipant) => {
        tableData.value.forEach((employee) => {
          if (addedParticipant.userUuid === employee.userUuid && addedParticipant.userRole !== employee.userRole) {
            employee.userRole = addedParticipant.userRole
          }
        })
        checkedEmployees.value.forEach((employee) => {
          if (addedParticipant.userUuid === employee.userUuid && addedParticipant.userRole !== employee.userRole) {
            employee.userRole = addedParticipant.userRole
          }
        })
      })
    }

    const onCurrentPageChangeActivities = (page) => {
      selectedPageActivities.value = page
      let payload = {} as any
      payload = {
        filter: {
          limit: itemsPerPageActivities.value,
          offset: itemsPerPageActivities.value * (page - 1),
          status: 2
        }
      }
      payload.filter.orderBy = {}
      payload.filter.orderBy[activeSortBy.value] = 'desc'
      if (tableSearchActivities.value !== '') {
        payload.filter.title = tableSearchActivities.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload)
    }
    const onItemsPerPageChangeActivities = (rowsPerPage, page) => {
      itemsPerPageActivities.value = rowsPerPage
      let payload = {} as any
      payload = {
        filter: {
          limit: rowsPerPage,
          offset: rowsPerPage * (selectedPageActivities.value - 1),
          status: 2
        }
      }
      payload.filter.orderBy = {}
      payload.filter.orderBy[activeSortBy.value] = 'desc'
      if (tableSearchActivities.value !== '') {
        payload.filter.title = tableSearchActivities.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload).then(() => {})
    }
    const currentAccount = computed(() => store.getters.currentAccount)

    const onDeployClicked = () => {
      // if (addedParticipants.value.length < teamMinSize.value) {
      //   ElNotification.error({
      //     message: 'At least ' + teamMinSize.value + ' participants needs to be selected',
      //     dangerouslyUseHTMLString: true,
      //     customClass: 'houston-notification',
      //     position: 'top-left'
      //   })
      //   return false
      // }
      // if (addedActivities.value.length < 1) {
      //   ElNotification.error({
      //     message: 'At least 1 activity needs to be selected',
      //     dangerouslyUseHTMLString: true,
      //     customClass: 'houston-notification',
      //     position: 'top-left'
      //   })
      //   return false
      // }

      deployDialogVisible.value = true
    }

    const orderByGroupNumber = (participants) => {
      const alteredParticipants = JSON.parse(JSON.stringify(participants))
      alteredParticipants.filter(item => true).forEach((participant, i, a) => {
        participant.groupNumber = getGroupNumber(participant.userUuid)
      })
      alteredParticipants.sort((a, b) => (a.groupNumber > b.groupNumber) ? 1 : ((b.groupNumber > a.groupNumber) ? -1 : 0))
      alteredParticipants.forEach((participant, i, a) => {
        delete participant.groupNumber
      })

      return alteredParticipants
    }

    const tableHeader = ref([
      {
        key: 'checkbox',
        sortable: false
      },
      {
        name: 'Employee',
        key: 'employee',
        sortable: false
      },
      {
        name: 'Business Unit',
        key: 'businessUnit',
        sortable: false
      },
      {
        name: 'Division',
        key: 'division',
        sortable: false
      },
      {
        name: 'Department',
        key: 'department',
        sortable: false
      },
      {
        name: 'Cost Centre',
        key: 'costCentre',
        sortable: false
      },
      {
        name: 'Manager',
        key: 'manager',
        sortable: false
      }

    ])
    const tableHeaderActivities = ref([
      {
        key: 'checkbox',
        sortable: false
      },
      {
        name: 'NAME AND DESCRIPTION',
        key: 'nameAndDescription',
        sortable: true
      },
      {
        name: 'LAST ACTION',
        key: 'lastAction',
        sortable: true
      },
      {
        name: 'DURATION',
        key: 'duration',
        sortable: true
      }
    ])
    let timeoutMenu = 0 as any
    const searchedParticipants = computed(() => {
      clearTimeout(timeoutMenu)
      return allAddedParticipants.value.filter((participant) => {
        return (
          participant.fullName
            .toLowerCase()
            .indexOf(searchParticipantString.value.toLowerCase()) !== -1 ||
              participant.email
                .toLowerCase()
                .indexOf(searchParticipantString.value.toLowerCase()) !== -1
        )
      })
    })

    const onSendNowPressed = () => {
      deployLoading.value = true

      const payload = {} as any

      payload.learningPathUuid = learningPathUuid
      store.dispatch(Actions.API_DEPLOY_LEARNING_PATH_NOW, payload).then((response) => {
        deployDialogVisible.value = false
      }).catch((response) => {
        deployDialogVisible.value = false
        deployLoading.value = false

        ElNotification.error({
          message: response.data.error.message,
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'top-left'
        })
      })
    }

    watch(searchedParticipants, (participants, oldParticipants) => {
      timeoutMenu = setTimeout(() => {
        MenuComponent.reinitialization()
      }, 500)
    })

    onMounted(() => {
      // Get the user's timezone

      console.log("User's timezone: " + userTimezone)
      if (!currentAccount.value) {
        // this is the case when the current account was not loaded yet
        watch(currentAccount, (newValue, oldValue) => {
          remoteFacilitatorSearchMethod()

          store.dispatch(MutationsActions.API_GET_MUTATE_LEARNING_PATH, learningPathUuid).then((response) => {
            // addedParticipants.value = typeof (unref(learningPath).deployMetadata.employees) !== 'undefined' ? unref(learningPath).deployMetadata.employees : []
            addedActivities.value = typeof (unref(learningPath).deployMetadata.activities) !== 'undefined' ? unref(learningPath).deployMetadata.activities!.filter(item => true) : []
            checkedActivities.value = typeof (unref(learningPath).deployMetadata.activities) !== 'undefined' ? unref(learningPath).deployMetadata.activities!.filter(item => true) : []

            addedActivities.value.forEach((addedActivity) => {
              if (addedActivity.date) {
                dateByActivity.value[addedActivity.activityTemplateUuid] = new Date(addedActivity.date + ' ' + addedActivity.time.start)
              }
            })
            facilitatedScheduledAt.value = unref(learningPath)!.scheduledAt
            selectedFacilitator.value = unref(learningPath)!.facilitators
            // checkedEmployees.value = typeof (unref(learningPath).deployMetadata.employees) !== 'undefined' ? unref(learningPath).deployMetadata.employees!.filter(item => true) : []
            // allTeams.value = unref(learningPath).deployMetadata.teams
            // addedParticipants.value = orderByGroupNumber(addedParticipants.value)
            // addedParticipants.value.forEach((participant) => {
            //   allAddedParticipants.value.push(participant)
            // })
          })

          // store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, employeesPayload).then(() => {
          //   store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES_COUNT).then(() => {
          //     MenuComponent.reinitialization()
          //   })
          // })

          const payload = { filter: { limit: itemsPerPage.value, offset: 0, status: 2, orderBy: { lastOpened: 'desc' } } }
          store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload).then(() => {
            const countPayload = { filter: { status: 2 } }
            store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT, countPayload).then(() => {
              MenuComponent.reinitialization()
            })
          })
          if (typeof socket.value.on === 'function') {
            socket.value.on('LEARNING_PATH_UPDATED', (eventData) => {
              if (eventData.learningPath.status === 2) {
                // ElNotification.success({
                //   message: 'Learning "' + unref(learningPath).title + '" was successfully deployed',
                //   dangerouslyUseHTMLString: true,
                //   customClass: 'houston-notification',
                //   position: 'top-left'
                // })
                deployLoading.value = false
              }
            })
          }
        })
        return
      }
      remoteFacilitatorSearchMethod()

      // this is the case when the account already exists
      store.dispatch(MutationsActions.API_GET_MUTATE_LEARNING_PATH, learningPathUuid).then((response) => {
        // addedParticipants.value = typeof (unref(learningPath).deployMetadata.employees) !== 'undefined' ? unref(learningPath).deployMetadata.employees : []
        addedActivities.value = typeof (unref(learningPath).deployMetadata.activities) !== 'undefined' ? unref(learningPath).deployMetadata.activities!.filter(item => true) : []
        checkedActivities.value = typeof (unref(learningPath).deployMetadata.activities) !== 'undefined' ? unref(learningPath).deployMetadata.activities!.filter(item => true) : []

        addedActivities.value.forEach((addedActivity) => {
          if (addedActivity.date) {
            dateByActivity.value[addedActivity.activityTemplateUuid] = new Date(addedActivity.date + ' ' + addedActivity.time.start)
          }
        })
        facilitatedScheduledAt.value = unref(learningPath)!.scheduledAt
        selectedFacilitator.value = unref(learningPath)!.facilitators

        // checkedEmployees.value = typeof (unref(learningPath).deployMetadata.employees) !== 'undefined' ? unref(learningPath).deployMetadata.employees!.filter(item => true) : []
        // allTeams.value = unref(learningPath).deployMetadata.teams
        // addedParticipants.value = orderByGroupNumber(addedParticipants.value)
        // addedParticipants.value.forEach((participant) => {
        //   allAddedParticipants.value.push(participant)
        // })
      })

      // store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES, employeesPayload).then(() => {
      //   store.dispatch(MutationsActions.API_GET_MUTATE_EMPLOYEES_COUNT).then(() => {
      //     MenuComponent.reinitialization()
      //   })
      // })

      const payload = { filter: { limit: itemsPerPage.value, offset: 0, status: 2, orderBy: { lastOpened: 'desc' } } }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES, payload).then(() => {
        const countPayload = { filter: { status: 2 } }
        store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT, countPayload).then(() => {
          MenuComponent.reinitialization()
        })
      })
      if (typeof socket.value.on === 'function') {
        socket.value.on('LEARNING_PATH_UPDATED', (eventData) => {
          if (eventData.learningPath.status === 2) {
            // ElNotification.success({
            //   message: 'Learning "' + unref(learningPath).title + '" was successfully deployed',
            //   dangerouslyUseHTMLString: true,
            //   customClass: 'houston-notification',
            //   position: 'top-left'
            // })
            deployLoading.value = false
          }
        })
      }
    })
    return {
      objective,
      participantsDialogVisible,
      activitiesDialogVisible,
      tableData,
      tableHeader,
      loadMore,
      infiniteHandler,
      handleSelectionChange,
      onCurrentPageChange,
      onItemsPerPageChange,
      itemsPerPage,
      onCheckEmployee,
      selectAllPerPageCheckbox,
      employeesCount,
      tablePagesCount,
      tableLoading,
      checkedEmployees,
      selectedPage,
      tableSearch,
      onItemsSearch,
      allParticipantsMustAnswer,
      onCheckAllParticipantsMustAnswer,
      notifyManager,
      onCheckNotifyManager,
      onAddParticipants,
      checkedParticipants,
      onCheckParticipant,
      allTeams,
      onLoadMoreParticipants,
      selectAllParticipants,
      onSelectAllParticipants,
      getGroupNumber,
      tableDataActivities,
      tableHeaderActivities,
      buildTemplateImageUrl,
      formatActivitiesTableDate,
      formatSecondsToMinutes,
      getEtagForTemplate,
      formatHumanDate,
      tableLoadingActivities,
      tablePagesCountActivities,
      onCurrentPageChangeActivities,
      onItemsPerPageChangeActivities,
      onCheckActivity,
      checkedActivities,
      tableSearchActivities,
      itemsPerPageActivities,
      selectedPageActivities,
      onItemsSearchActivities,
      onAddActivities,
      generatedRandomColor,
      onLoadMoreActivities,
      onRemoveActivity,
      dateByActivity,
      handleActivitiesDate,
      getApiKey,
      editorSettings,
      learningPathTitle,
      learningPathDescription,
      deployDialogVisible,
      formatLastActionDatatable,
      learningPath,
      learningPathSettings,
      onLearningPathTitleChanged,
      onLearningPathDescriptionChanged,
      onLearningPathObjectiveChanged,
      selectedEmployees,
      onParticipantsDialogCLose,
      onParticipantsDialogOpen,
      addedParticipants,
      addedParticipantsModel,
      handleOnClickedItem,
      searchParticipantString,
      searchedParticipants,
      onRemoveParticipants,
      onAssignUser,
      onRemoveParticipant,
      addedActivities,
      onActivitiesDialogClose,
      removeActivityLoading,
      onActivitiesDialogOpen,
      onDeployClicked,
      timePicker,
      lowerLimitDate,
      upperLimitDate,
      onDatePickerOpen,
      lowerLimitTime,
      handleDateSelection,
      upperLimitTime,
      formatDate,
      onSendNowPressed,
      buildTemplateImageUri,
      currentAccount,
      deployLoading,
      onCheckAllParticipantsHavePreIntro,
      createLearningGroupDialog,
      createGroupTitle,
      allGroups,
      onAddGroupActionClicked,
      learningPathUuid,
      addGroupError,
      onAllParticipantsAreInClassRoom,
      userTimezone,
      onIsFacilitatedChecked,
      remoteFacilitatorSearchMethod,
      selectedFacilitator,
      facilitatorsLoading,
      facilitators,
      facilitatedScheduledAt,
      handleScheduledAtUpdated,
      handleChooseFacilitator,
      quillEditorTitle,
      quillEditorDescription,
      quillOptions
    }
  }
})
